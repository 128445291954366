











import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { ExpertiseProposed } from '@/services/iResourceService';
import PgaSingleExpertiseProposed from "./PgaSingleExpertiseProposed.vue";

@Component({
    components:
    {
        PgaSingleExpertiseProposed,
    }
})
export default class PgaExpertisesProposedList extends Vue
{
    @Prop({ default: function() { return new Array<ExpertiseProposed>() } }) readonly ExpertisesProposedList!: Array<ExpertiseProposed>;
}
