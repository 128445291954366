
































import { Component } from "vue-property-decorator"

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { MyExpertisesStoreDefault } from './store';
import { MyExpertisesTableView, Skill } from '@/services/iResourceService';
import PgaExpertisesTableIndex from '@/feature/Resource/Expertises/Components/PgaExpertisesTable.vue';
import PgaStatusSkill from "./Components/PgaStatusSkill.vue";

@Component({
	components:
	{
		PgaExpertisesTableIndex,
		PgaStatusSkill,
	}
})
export default class MyExpertisesIndex extends mixins(SharedMixin)
{
	private isLoading = false;

	headers: Array<any> =
    [
		{
			text: 'Code',
			align: 'start',
			sortable: true,
            value: 'Code',
            width: "10%",
			keySearch: "co",
        },
        {
			text: 'Description',
			align: 'start',
			sortable: true,
			value: 'Description',
			keySearch: "de",
        },
        {
			text: 'Skill',
			align: 'start',
			sortable: true,
			value: 'Skill',
			width: "40%",
		},
		{
			text: 'Status',
			align: 'start',
			sortable: true,
			value: 'Status',
			width: "10%",
        },
    ];

	get myExpertises() : MyExpertisesTableView
	{
		return MyExpertisesStoreDefault.MyExpertises;
	}

	rowClicked (item: any) : void
    {
		const id = item.Id;
		this.$router.push({ name: 'expertisedetails', params: { id }});
	}

	selectExpertises () : void
	{
		this.$router.push({ name: 'myexpertisesselect' });
	}

	color(skill: Skill): string
	{
		switch (skill)
		{
			case Skill.Basic:
				return "error";
			case Skill.Intermediate:
				return "warning";
			case Skill.Advanced:
				return "success";
			default:
				return "";
		}
	}

	async mounted(): Promise<void>
	{
		try
		{
			this.isLoading = true;
			await MyExpertisesStoreDefault.GetMyExpertises();
		} 
		finally
		{
			this.isLoading = false;
		}
	}
}
