









































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Guid } from "guid-typescript";

import { ApproveUserExpertiseProposedCommand, ApproveUserExpertiseProposedResponse, 
    DenyUserExpertiseProposedCommand, DenyUserExpertiseProposedResponse, ExpertiseProposed, Skill } from '@/services/iResourceService';
import { MyExpertisesStoreDefault } from '../store';
import { SharedModule } from "@/feature/Shared/store";

@Component({})
export default class PgaSingleExpertiseProposed extends Vue
{
    @Prop({ type: Object }) readonly ExpertiseProposed!: ExpertiseProposed;

    private stateApprove = 0;
    private stateDeny = 0;

    private async approveProposal(userId: Guid, expertiseId: Guid) : Promise<void>
    {
        this.stateApprove = 1;
        this.stateDeny = 0;
        
        const command: ApproveUserExpertiseProposedCommand =
        {
            UserId: userId,
            ExpertiseId: expertiseId,
        }

        const response: ApproveUserExpertiseProposedResponse = await MyExpertisesStoreDefault.ApproveProposal(command);
        
        this.stateApprove = response.IsApproved? 2 : 0;
    }

    private async denyProposal(userId: Guid, expertiseId: Guid) : Promise<void>
    {
        this.stateApprove = 0;
        this.stateDeny = 1;

        const command: DenyUserExpertiseProposedCommand =
        {
            UserId: userId,
            ExpertiseId: expertiseId,
        }
        
        const response: DenyUserExpertiseProposedResponse = await MyExpertisesStoreDefault.DenyProposal(command);
        
        this.stateDeny = response.IsDenied? 2 : 0;
    }

    private skillString(skill: Skill)
    {
        switch(skill)
        {
            case Skill.Basic:
                return "Basic";
            case Skill.Intermediate:
                return "Intermediate";
            case Skill.Advanced:
                return "Advanced";
        }
    }

    private contactUser() : void
    {
        SharedModule.openTeams(this.ExpertiseProposed.User.Email);
    }
}
