































import { Model, Prop } from 'vue-property-decorator';
import { Guid } from 'guid-typescript';
import Component, { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

import { SelectMyExpertisesCommand, MyExpertiseViewModel, ExpertiseIdSkill, Skill } from '@/services/iResourceService';
import { MyExpertisesStoreDefault } from '../store';

@Component({})
export default class PgaMyExpertisesSelect extends mixins(SharedMixin)
{
    @Model("", { type: Object }) readonly Model!: SelectMyExpertisesCommand;
    @Prop({ default: false }) readonly isLoading!: boolean;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    headers: Array<any> = [
        {
            text: "Code",
            align: "start",
            sortable: true,
            value: "Code",
            width: "10%",
            keySearch: "co",
        },
        {
            text: "Description",
            align: "start",
            sortable: true,
            value: "Description",
            keySearch: "de",
        },
        {
            text: "Skill",
            align: "start",
            sortable: false,
            value: "Skill",
            width: "40%",
        },
    ];

    get listExpertises() : Array<MyExpertiseViewModel>
    {
        const listExpertiseSkills: Array<MyExpertiseViewModel> = new Array<MyExpertiseViewModel>(...MyExpertisesStoreDefault.MyExpertises.Expertises);
        const listExpertiseIdSkills: Array<Guid> = listExpertiseSkills.map(u => u.Id);

        MyExpertisesStoreDefault.AllExpertisesTable.Expertises.forEach(e =>
        {
            if(!listExpertiseIdSkills.includes(e.Id))
                listExpertiseSkills.push({ Id: e.Id, Code: e.Code, Description: e.Description, Skill: 0, Status: 0 })
        });

        return listExpertiseSkills;
    }

    get selectedMyExpertises() : Array<MyExpertiseViewModel>
    {
        const selectedExpertiseIdSkills: Array<MyExpertiseViewModel> = new Array<MyExpertiseViewModel>();
        MyExpertisesStoreDefault.MyExpertisesSelect.Command?.Expertises.forEach(es => selectedExpertiseIdSkills.push({ Id: es.ExpertiseId, Code: 0, Description: '', Skill: es.Skill, Status: 0}))
        return selectedExpertiseIdSkills ?? new Array<MyExpertiseViewModel>();
    }
    set selectedMyExpertises(value: Array<MyExpertiseViewModel>)
    {
        const expertiseIdSkills: Array<ExpertiseIdSkill> = new Array<ExpertiseIdSkill>();
        value.forEach(e => expertiseIdSkills.push({ ExpertiseId: e.Id, Skill: e.Skill }));

        MyExpertisesStoreDefault.SET_MYEXPERTISES_SELECT_EXPERTISES(expertiseIdSkills);
    }

    private getSkill(id: Guid) : Skill
    {
        const expertiseById = MyExpertisesStoreDefault.MyExpertisesSelect.Command?.Expertises
            .find(es => es.ExpertiseId == id);
        console.log(expertiseById);
        return expertiseById?.Skill ?? Skill.Basic;
    }

    private showSkill(expertiseId: Guid): boolean
    {
        return this.selectedMyExpertises.map(e => e.Id).includes(expertiseId);
    }

    private setSkill(expertiseId: Guid, skill: Skill): void
    {
        const expertiseIdSkill: ExpertiseIdSkill = 
        {
            ExpertiseId: expertiseId,
            Skill: skill,
        };
        MyExpertisesStoreDefault.SET_MYEXPERTISES_SELECT_SKILL(expertiseIdSkill);
    }

    get Errors() : Map<string, Array<string>>
    {
        return MyExpertisesStoreDefault.MyExpertisesSelect.Errors;
    }
}
