













import { Component } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { MyExpertisesStoreDefault, SelectMyExpertisesModel } from './store';
import PgaMyExpertisesSelect from './Components/PgaMyExpertisesSelect.vue';

@Component({
    components:
    {
		PgaMyExpertisesSelect
	}
})

export default class MyExpertisesPageSelect extends mixins(SharedMixin)
{
	private isLoading = true;
	
	get MyExpertisesSelects() : SelectMyExpertisesModel
	{
		return MyExpertisesStoreDefault.MyExpertisesSelect;
	}

	async Selects() : Promise<void>
	{
		try
		{
			await MyExpertisesStoreDefault.SelectExpertises();
			this.$router.push({name: 'myexpertisesindex'});
		}
		// eslint-disable-next-line no-empty
		catch(error) { }
	}

	undo() : void 
	{
		this.$router.go(-1);
    }
    
	async mounted() : Promise<void>
	{
		this.isLoading = true;
		MyExpertisesStoreDefault.InitSelectMyExpertises();
		await MyExpertisesStoreDefault.GetExpertisesTable();
		this.isLoading = false;
	}

	beforeDestroy(): void
	{
		MyExpertisesStoreDefault.MyExpertisesSelect.Errors = new Map<string, Array<string>>();
	}
}
