import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '@/infrastructure/store'
import { Guid } from 'guid-typescript';
import { SharedModule } from '@/feature/Shared/store';

import pgaDiContainer from '@/App.container';
import IResourceService from '@/services/iResourceService';
import ResourceService from '@/services/ResourceService';

import { MyExpertisesTableView, MyExpertiseViewModel, ListExpertises, ExpertiseModel, ExpertiseIdSkill, ListExpertisesProposed, ExpertiseProposed,
        SelectMyExpertisesCommand, ApproveUserExpertiseProposedCommand, DenyUserExpertiseProposedCommand,
        SelectMyExpertisesResponse, ApproveUserExpertiseProposedResponse, DenyUserExpertiseProposedResponse } from '@/services/iResourceService';

export interface MyExpertisesState
{
    MyExpertises: MyExpertisesTableView;
    MyExpertisesSelect: SelectMyExpertisesModel;

    ListExpertisesProposedByAreaManager: ListExpertisesProposed;

    AllExpertisesTable: ListExpertises;
}

export interface SelectMyExpertisesModel
{
    Command: SelectMyExpertisesCommand | null;
    Response: SelectMyExpertisesResponse | null;
    Errors: Map<string, Array<string>>;
}

@Module({ namespaced: true, dynamic: true, store, name: 'MyExpertisesModule', })
class MyExpertisesStore extends VuexModule implements MyExpertisesState
{
    private resourceService: IResourceService = pgaDiContainer.get<IResourceService>(ResourceService);

    public MyExpertises: MyExpertisesTableView =
    {
        Expertises: new Array<MyExpertiseViewModel>(),
    };

    public MyExpertisesSelect: SelectMyExpertisesModel =
    {
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>(),
    };

    public ListExpertisesProposedByAreaManager: ListExpertisesProposed =
    {
        Expertises: new Array<ExpertiseProposed>(),
    };

    public AllExpertisesTable: ListExpertises =
    {
        Expertises: new Array<ExpertiseModel>(),
    }

    // List Expertises Table
    @Action({ rawError: true })
    public async GetExpertisesTable(): Promise<void>
    {
        const expertises: ListExpertises = await this.resourceService.GetAllExpertises();
        this.SET_EXPERTISES_TABLE(expertises);
    }
    @Mutation
    public SET_EXPERTISES_TABLE(expertises: ListExpertises)
    {
        this.AllExpertisesTable = expertises;
    }
    

    // GET & SET MYEXPERTISES
    @Action({ rawError: true })
    public async GetMyExpertises(): Promise<void>
    {
        const expertises: MyExpertisesTableView = await this.resourceService.GetMyExpertises();
        this.SET_MYEXPERTISES_TABLE(expertises);
    }
    @Mutation
    public SET_MYEXPERTISES_TABLE(expertises: MyExpertisesTableView)
    {
        this.MyExpertises = expertises;
    }

    // Select MyExpertises
    @Action({ rawError: true })
    public async SelectExpertises(): Promise<SelectMyExpertisesResponse>
    {
        try
        {
            let response: SelectMyExpertisesResponse = { Id: Guid.createEmpty(), };
            SharedModule.SET_ISLOADING(true);

            if (this.MyExpertisesSelect.Command)
                response = await this.resourceService.SelectMyExpertises(this.MyExpertisesSelect.Command);
            
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_MYEXPERTISES_SELECT_ERRORS(errs);

            throw errs;
        }
    }
    @Action({ rawError: true })
    public InitSelectMyExpertises()
    {
        const command: SelectMyExpertisesCommand =
        {
            Expertises: new Array<ExpertiseIdSkill>(),
        }

        const expertiseIdSkills: Array<ExpertiseIdSkill> = new Array<ExpertiseIdSkill>();
        this.MyExpertises.Expertises.forEach(e => expertiseIdSkills.push({ ExpertiseId: e.Id, Skill: e.Skill }));

        this.SET_MYEXPERTISES_SELECT_COMMAND(command);
        this.SET_MYEXPERTISES_SELECT_EXPERTISES(expertiseIdSkills);
    }
    @Mutation
    public SET_MYEXPERTISES_SELECT_COMMAND(command: SelectMyExpertisesCommand)
    {
        this.MyExpertisesSelect.Command = command;
    }
    @Mutation
    public SET_MYEXPERTISES_SELECT_EXPERTISES(expertises: Array<ExpertiseIdSkill>)
    {
        if(this.MyExpertisesSelect.Command)
            this.MyExpertisesSelect.Command.Expertises = expertises;
    }
    @Mutation
    public SET_MYEXPERTISES_SELECT_SKILL(expertiseIdSkill: ExpertiseIdSkill)
    {
        if(this.MyExpertisesSelect.Command)
            this.MyExpertisesSelect.Command.Expertises.forEach(es =>
                {
                    if(es.ExpertiseId == expertiseIdSkill.ExpertiseId)
                        es.Skill = expertiseIdSkill.Skill;
                });
    }
    @Mutation
    public SET_MYEXPERTISES_SELECT_ERRORS(errors: Map<string, Array<string>>)
    {
        this.MyExpertisesSelect.Errors = new Map<string, Array<string>>(errors);
    }

    //List Expertises Proposed, ready to be approved or not
    @Action({ rawError: true })
    public async GetAllExpertisesProposed()
    {
        SharedModule.SET_ISLOADING(true);
        const expertisesProposed = await this.resourceService.GetAllExpertisesProposedByAreaManager();
        this.SET_EXPERTISES_PROPOSED(expertisesProposed);
        SharedModule.SET_ISLOADING(false);
    }
    @Mutation
    SET_EXPERTISES_PROPOSED(expertisesProposed: ListExpertisesProposed) {
        this.ListExpertisesProposedByAreaManager = expertisesProposed;
    }

    // Approve Expertise Proposed
    @Action({ rawError: true })
    public async ApproveProposal(cmd: ApproveUserExpertiseProposedCommand): Promise<ApproveUserExpertiseProposedResponse>
    {
        try 
        {
            let response: ApproveUserExpertiseProposedResponse = { IsApproved: false };
            SharedModule.SET_ISLOADING(true);

            response = await this.resourceService.ApproveExpertiseProposed(cmd);
            
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);

            throw error;
        }
    }

    // Deny Expertise Proposed
    @Action({ rawError: true })
    public async DenyProposal(cmd: DenyUserExpertiseProposedCommand): Promise<DenyUserExpertiseProposedResponse>
    {
        try 
        {
            let response: DenyUserExpertiseProposedResponse = { IsDenied: false };
            SharedModule.SET_ISLOADING(true);

            response = await this.resourceService.DenyExpertiseProposed(cmd);
            
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);

            throw error;
        }
    }
}

function parseErrors(error: any): Map<string, Array<string>> {
    const errs = new Map<string, Array<string>>();

    for (const [key, value] of Object.entries(error.response.data)) {
        const messages = value as Array<string>;
        errs.set(key, messages);
    }

    return errs;
}

export const MyExpertisesStoreDefault = getModule(MyExpertisesStore);