









import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { StatusSkill } from "@/services/iResourceService";

@Component({})
export default class PgaStatusSkill extends Vue
{
	@Model("", { type: Number }) Model!: StatusSkill;

	get color(): string {
		switch (this.Model) {
			case StatusSkill.Approved:
				return "success";
			case StatusSkill.Proposed:
				return "yellow";
			default:
				return "error";
		}
	}

	get icon(): string {
		switch (this.Model) {
			case StatusSkill.Approved:
				return "mdi-check-decagram";
			case StatusSkill.Proposed:
				return "mdi-offer";
			default:
				return "mdi-alert-circle";
		}
	}

	get tooltip(): string {
		switch (this.Model) {
			case StatusSkill.Approved:
				return "Approved";
			case StatusSkill.Proposed:
				return "Proposed";
			default:
				return "Expired";
		}
	}
}
