var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mb-5",attrs:{"justify":"center"}},[_c('v-card',{staticClass:"elevation-12",attrs:{"width":"650"}},[_c('v-card-title',[_vm._v(" Expertise Proposal ")]),_c('v-card-text',[_c('strong',[_vm._v(" "+_vm._s(_vm.ExpertiseProposed.User.FullName)+" ")]),_vm._v(" has requested to modify owns Skill for "),_c('strong',[_vm._v(" "+_vm._s(_vm.ExpertiseProposed.Expertise.Description)+" ")]),_vm._v(" in "),_c('strong',[_vm._v(" "+_vm._s(_vm.skillString(_vm.ExpertiseProposed.Expertise.Skill))+" ")])]),_c('v-card-actions',[_c('v-row',{staticClass:"mx-0",attrs:{"justify":"end","dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","color":"purple"},on:{"click":function($event){return _vm.contactUser()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-microsoft-teams ")])],1)]}}])},[_c('span',[_vm._v(" Contact "+_vm._s(_vm.ExpertiseProposed.User.FullName)+" on Teams ")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"3","offset":"4"}},[(_vm.stateApprove == 0 && _vm.stateDeny == 0)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","color":"success"},on:{"click":function($event){return _vm.approveProposal(_vm.ExpertiseProposed.User.Id, _vm.ExpertiseProposed.Expertise.Id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-checkbox-marked-circle-outline ")]),_c('strong',[_vm._v(" Approve ")])],1)]}}],null,false,1771843329)},[_c('span',[_vm._v(" Approve ")])]):(_vm.stateApprove == 1)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"green"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[(_vm.stateApprove == 0 && _vm.stateDeny == 0)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","color":"error"},on:{"click":function($event){return _vm.denyProposal(_vm.ExpertiseProposed.User.Id, _vm.ExpertiseProposed.Expertise.Id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close-circle-outline ")]),_c('strong',[_vm._v(" Deny ")])],1)]}}],null,false,46902886)},[_c('span',[_vm._v(" Deny ")])]):(_vm.stateDeny == 1)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"red"}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }