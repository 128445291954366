





import { Component } from "vue-property-decorator";
import { MyExpertisesStoreDefault } from './store';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaExpertisesProposedList from './Components/PgaExpertisesProposedList.vue';
import { ExpertiseProposed } from '@/services/iResourceService';

@Component({
    components:
    {
        PgaExpertisesProposedList,
    }
})
export default class ExpertisesProposedPageList extends mixins(SharedMixin)
{
    get ExpertisesProposedList() : Array<ExpertiseProposed>
    {
        return MyExpertisesStoreDefault.ListExpertisesProposedByAreaManager.Expertises;
    }
    
    async mounted() : Promise<void>
    {
        await MyExpertisesStoreDefault.GetAllExpertisesProposed();       
    }
}
